@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

.App {
  display: flex;
  /* overflow-x: hidden; */
  /* ooverflow: hidden; */
  /* background-image: url('./assets/wbg.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  /* font-family: "Raleway", sans-serif!important;*/
  font-family: "Oswald", sans-serif; 
  font-optical-sizing: auto;
  font-style: normal;
}
.backgroundd{
  width: 99.1vw!important;
  height: 100vh!important;
  /* background-color: rgb(255, 224, 129); */
  position: fixed;
  top: 0;
  left: 0; 
}
#myVideo{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.App section{
  /* overflow-x: hidden; */
  /* overflow: hidden; */
}
/* .App .btnStyles:hover > .App #trailer{
  background-color: blue;
} */
#trailer{
  height: 2000px;
  width: 4000px;
  border-radius: 100px;

  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 5;

  pointer-events: none;
  opacity: 1;
  transition: all 3s ease;
  transition: transform 1s ease-out; /* Smooth initial animation */
  will-change: transform;
  /* background-color: red; */
}
.trailerOutBtn{
  background: radial-gradient(circle, rgba(255, 0, 0, 0), rgba(4, 10, 52, 0.732), rgba(4, 10, 52, 0.937), rgb(4, 10, 52),rgb(4, 10, 52),rgb(4, 10, 52));
  transition: all 3s ease;
  
}
@keyframes follow-mouse {
  to {
      transform: translate(var(--mouse-x), var(--mouse-y));
  }
}

/* .trailerInBtn{
  width: 50px;
  transition: all 3s ease;
} */
.onTop{
  position: relative!important;
  z-index: 11!important;
}

@media only screen and (max-width: 1150px) {
#about{
  display: flex;
  flex-wrap: wrap;
}
#about div{
  width: 100%;
}
#about div p{
  text-align: center;
}
.cardsContainer{
  justify-content: center;
}
.btnHolder{
  display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  padding: 0px!important;
  width: 100vw!important;
}
.btnStyles{
  padding: 10px 20px!important;
  font-size: 16px!important;
  margin: 5px!important;
}
.cardContainer{
  max-width: 90vw!important;
}
.cardContainer article{
  max-width: 90vw!important;
}
.cardContainer header{
  max-width: 90%!important;
}
#res{
  display: flex;
  flex-wrap: wrap;
}
.resPdf{
  height: 400px;
  margin: auto!important;
}
.resImgContain{
  width: 100%!important;
  margin-bottom: 40px;
}
.resImgContain div{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.resImgContain div p{
  width: 90%;
}
.resImgContain div a{
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resImgs{
  margin-right: 0px!important;
  width: 150px!important;
  height: 150px!important;
}
.experience{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.experience div{
  width: 90%!important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.experience div img{
  display: flex;
  margin: auto;
}
.order1{
  order: 0;
}
.order2{
  margin-top: 20px;
  order: 1;
}
.hero{
  width: auto!important;
}
.backgroundd{
  width: auto!important;
}
.projPageWrap{
  width: 100vw!important;
}
#home h2{
  font-size: 50px;
}
#home h3{
  font-size: 30px;
  width: 90%;
  display: flex;
  margin: auto;
  padding-bottom: 20px;
}
.skillsContain{
  padding: 0px!important;
}
.skillcard{
  width: 150px!important;
  height: 150px!important;
  font-size: 12px;
}
#home div button{
  display: flex;
  margin: auto!important;
}
.loganWrap{
  width: 320px!important;
}
.logan{
  width: 100px!important;
  height: 150px!important;
  margin-right: 0px!important;
}
}