.hero{
  height: 100vh;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background-size: cover;
  flex-grow: 1;
  width:99vw;
  /* cursor:text; */
}
.hero div{
  width: 100%;
  padding-bottom: 150px;
}
.hero h2{
  text-align: center;
  margin-bottom: 0px;
  width: 100%;
  font-size: 70px;
  color: white;
}
.hero h2 span{
  color: #47aef2;
}
.hero button{
  text-align: center;
  margin: auto;
  display: block;

}.hero button a{
  text-align: center;
  margin: auto;
  display: block;
  color: white!important;
  text-decoration: none;
}
.hero h3{
  text-align: center;
  width: 100%;
  font-size: 40px;
  margin-top: 5px;
  color: white;
}
.projPageWrap{

}

.cardsContainer{
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  /* background-image: url('../../assets/experience_white_background.png'); */
  background-size: 450px;
  background-repeat: no-repeat;
  align-items: stretch;
  overflow-x: hidden; 
  position: relative;
  padding-top: 80px;
  min-height: 90vh;
  background: rgb(4, 10, 52);
}
.holderDiv{
  max-width: 80%;
  width: 420px;
  max-width: 100%;
  margin: 20px;
}
.projectsGraphic{
  position: absolute;
  width: 300px;
  height: 200px;
  background: blue;
}
.btnHolder{
  position: sticky;
    top: 0;
    z-index: 20;
    background: rgb(162, 162, 162);
    width: 98.4%;
    padding: 15px;

}
.skillsContain{
  padding-left: 20px;
  background: rgb(4, 10, 52);
}
.hide{
  display: none!important;
}
.react{
  background-image: url('../../assets/react.jpg');
}
.apollo{
  background-image: url('../../assets/apollo.jpg');
}
.css{
  background-image: url('../../assets/css.png');
  background-size: 60%!important;
  background-repeat: no-repeat;

}
.js{
  background-image: url('../../assets/js.png');
}
.jwt{
  background-image: url('../../assets/jwt.png');
}
.mongo{
  background-image: url('../../assets/mongo.png');
}
.mongoose{
  background-image: url('../../assets/mongoose.jpg');
}
.node{
  background-image: url('../../assets/node.png');
  background-size: 90%!important;
  background-repeat: no-repeat;
}
.stripe{
  background-image: url('../../assets/stripe.png');
}

.pwa{
  background-image: url('../../assets/pwa.png');
  background-size: contain!important;
  background-repeat: no-repeat;
}
.redux{
  background-image: url('../../assets/redux.png');
}
.seqelize{
  background-image: url('../../assets/sequelize.png');
}
.stripe{
  background-image: url('../../assets/stripe.png');
}
.gql{
  background-image: url('../../assets/graphql.png');
}
.github{
  background-image: url('../../assets/github.png');
}
.git{
  background-image: url('../../assets/git.jpg');
  background-size: contain!important;
  background-repeat: no-repeat;
}
.express{
  background-image: url('../../assets/ex.png');
}
.rest{
  background-image: url('../../assets/rest.png');
}
.mysql{
  background-image: url('../../assets/sql.png');
  background-size: contain!important;
  background-repeat: no-repeat;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(90deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.spin {
  animation: spin 2s ease-in-out;
}
.skillwrap{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  background: rgb(4, 10, 52);
}
.skillcard{
  display: flex;
  align-items: center;
  margin: 15px;
  width: 230px;
  height: 230px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  background-color: white;
}
.skillcard  div {
  width: 100%;
  text-align: center;
  align-items: center;
  align-self: center;
  display: flex;
  opacity: 0;
  height: 100%;
  background: rgba(74, 74, 74, 0.603);
  border-radius: 20px;
  transition: all .3s ease;
}
.skillcard p {
  text-align: center;
  /* display: flex; */
  color: white;
  margin: auto;
  width: 90%;
}
.skillcard:hover > div{
  opacity: 1;
  transition: all .3s ease;
}
#about{
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding-top: 40px;
  color: white!important;
  display: flex;
  background: rgb(4, 10, 52);
  justify-content: center;
}
#about div{
  max-width: 800px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: auto;
}
#about div img{
  margin-left: auto;
  margin-right: 100px;
  display: flex;
}
#about div p{
  text-align: left;
  width: 80%;
  display: flex;
  margin-left: auto;
  margin-right: 100px;
  padding-top: 30px;
  font-size: 20px;
}
.loganWrap{
  position: relative;
  top: 50px;
  display: grid!important;
  grid-template-columns: 3;
  grid-template-rows: 6;
  max-width: 650px!important;
  padding-top: 200px;
  padding-bottom: 100px;
}
.logan{
  max-width: 200px;
  max-height: 250px;
  object-fit:cover;
  margin: 0px!important;
  margin-bottom: 30px!important;
  margin-right: 30px!important;
}
@media only screen and (max-width: 1150px) {
  .loganWrap{
    padding-top: 100px;
    padding-bottom: 0px;
  }
.logan{
  width: 100px!important;
  height: 150px!important;
  margin-right: 0px!important;
}
#about div:last-child{
  padding: 20px;
}
#about div p{
  width: 100%!important;
  margin-right: 0px!important;
}
}
.l1{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}
.l2{
  position: relative;
  top: -100px;
}
.l5{
  position: relative;
  top: -100px;
}
.l6{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
  position: relative;
  top: -100px;
}
.l7{
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

#test{
  min-height: 90vh;
  background: rgb(4, 10, 52);
  padding-top: 0;
  display: flex;
  /* flex-direction: column;*/
  align-items: center; 
}
#test div{
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
}
.test{
  background-image: url('../../assets/quote.png');
  background-size: 90%;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: 2px solid rgb(174, 174, 174);
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: black;
  width: 350px;
  height: 360px;
  margin: auto;
}
.test img{
  border-radius: 100%;
  object-fit: contain;
  display: flex;
  margin: auto!important;
  width: 130px;
}
.test p{
  margin-left: auto!important;
  width: 300px!important;
  font-size: 23px!important;
}
.test h5{
  margin: auto;
  margin-bottom: 0px;
  font-size: 20px;
}
.test h6{
  margin: auto;
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #888888;
}
.pos1{
  position: absolute;
  z-index: 400;
  left: 15%;
  scale: .9;
  transition: all .4s ease;
}
.pos2{
  position: absolute;
  left: 40%;
  z-index: 500;
  scale: 1.1;
  transition: all .4s ease;
}
.pos3{
  position: absolute;
  left: 65%;
  z-index: 400;
  scale: .9;
  transition: all .4s ease;
}
.pos4{
  position: absolute;
  z-index: 300;
  left: 40%;
  scale: .8;
  transition: all .4s ease;
}
#res{
  width: 100%;
  min-height: 90vh;
  height: fit-content;
  padding-top: 130px;
  display: flex;
  justify-content: space-around;
  margin: auto;
  background: rgb(4, 10, 52);
}
#workContain{
  width: 100%;
  min-height: 95vh;
  padding-top: 100px;
  background: rgb(4, 10, 52);
  align-items: center;

}
.experience{
display: flex;
flex-wrap: wrap;
color: white;
width: 100%;
justify-content: center;
margin: auto;
padding-top: 60px;
padding-bottom: 60px;
/* background: rgb(4, 10, 52); */
}
.experience div{
  width: 40%;
  display: flex;
  flex-direction: column;

}
.experience div h2{
  font-size: 40px;
  margin: 0px;
}
.experience div h3{
  font-size: 20px;
  margin: 0px;
  color: #e7e7e7;
  font-weight: 400;
}
.experience div h4{
  font-size: 20px;
  margin: 0px;
  margin-bottom: 10px;
  color: #e7e7e7;
  font-weight: 400;
}
.experience div article{
  font-size: 22px;
  width: 90%;
}
.experience div article a{
  color: aqua;
}
.experience div img{
  max-width: 700px;
  width: 90%;
  border-radius: 12px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}
#proj{
  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;
  background: rgb(4, 10, 52);
}
#skill{
  background: rgb(4, 10, 52);
  margin-top: -30px;
  padding-top: 50px;
}
#skillContain{
  width: 100%;
  padding-top: 80px;
  background: rgb(4, 10, 52);
}
.logan{
  object-fit: cover;
  width: 400px;
  height: 500px;
  border-radius: 25px;
}
.resImgContain{
  display: flex;
  flex-direction: column;
  width: 40%;
}
.resImgs{
  width: 260px;
  height: 260px;
  object-fit: cover;
  border-radius: 20px;
  margin-right: 30px;
  scale: 1;
  transition: all .3s ease;
}
.resImgs:hover{
  scale: 1.1;
  transition: all .3s ease;
}
.resImgContain div p{
  color: white;
  font-size: 25px;
}
.resPdf{
  max-height: 700px;
  object-fit: contain;
  cursor: pointer;
  margin-right: 0px;
  margin-left: auto;
  border-radius: 8px;
}
.m-t-auto{
  margin-top: auto;
}
.m-b-auto{
  margin-bottom: auto;
}
.wave1{
  background-image: url('../../assets/wave1.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  padding-top: 250px;
  padding-bottom: 250px;
}
.blackText{
  color: black!important;
  font-weight: 700!important;
}
.redText{
  color: rgb(255, 0, 0)!important;
  font-weight: 700!important; 
}

